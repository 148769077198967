<template>
  <section>
     <turmaLista :id="this.id" :pg="this.pg" :escola_turm_p="this.escola_turm_p"
      :segmento_turm_p="this.segmento_turm_p"/>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import turmaLista from "@/components/turmas/turmaAll.vue"


export default defineComponent({
  props:{
    id:{},
    pg: {
      default:1
    },
    escola_turm_p: 0,
    segmento_turm_p: 0,
  },
  components:{
    turmaLista
  },
  data() {
    return {
    };
  },

});
</script>
